import {
	alpha,
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from "@mui/material";
import {
	Check,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	BubbleChart as StepsIcon,
} from "@mui/icons-material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import CustomizedDialog from "components/Dialog";
import { Card, CardBody, CardFooter, CardHeader } from "./Card";
import { CustomizedHeader } from "./Tools";
import { toast } from "react-toastify";
import { catalogSlice } from "stores/slices/catalog";
import CatalogService from "services/catalogService";

export default function CatalogSelector() {
	const theme = useTheme();
	const dispatch = useDispatch();	
	const catalogService = new CatalogService();

	const { game: mainGame } = useSelector((state) => state.game);
	const { catalog: mainCatalog } = useSelector((state) => state.catalog);

	// STATES  
	const [ selectedCatalog, setSelectedCatalog ] = useState(mainCatalog);
	const [ openDialog, setOpenDialog ] = useState(false);

	// VALIDATIONS
	const enabled = mainGame?._id ? true : false;
	const isCatalogSelected = selectedCatalog && selectedCatalog._id ? true : false;

	const keys = [ "catalogs", mainGame?._id ];
	// QUERIES
	const { data: catalogs } = useQuery(
		keys,
		async () =>
			await catalogService.getAll({
				gameId: mainGame?._id
			}),
		{
			enabled,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		}
	);

	// HANDLERS
	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleOpen = () => {
		setOpenDialog(true);
	};

	const handleSetCollection = () => {		
		dispatch(catalogSlice.actions.setCatalog(selectedCatalog));
		toast.success("Catalog selected successfully.");
		handleClose();
	};

	const handleChangeCatalog = (event) => {
		const id = event.target.value;
		if (selectedCatalog?._id !== id) {
			setSelectedCatalog(catalogs.find((g) => g._id === id));			
		}
	};

	useEffect(() => {						
		setSelectedCatalog(mainCatalog);
	}, [mainCatalog]);

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				onClick={handleOpen}
				px={1}
				sx={{
					border: "1px solid",
					borderColor: theme.palette.divider,
					borderRadius: theme.shape.borderRadius,
					height: 70,
					width: 240,
					maxWidth: 240,
					cursor: "pointer",
					transition: "all 0.1s ease-in-out",
					"&:hover": {
						backgroundColor: alpha("#fff", 0.07),
					},
				}}
			>
				<Box>
					<Typography variant="h6">
						{mainCatalog?.displayName ?? "Select a Catalog"}
					</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{mainCatalog?.catalogId ?? "Select a catalog"}
					</Typography>
				</Box>
				<Box p={0.5}>
					{openDialog ? (
						<KeyboardArrowUpIcon sx={{ color: theme.palette.primary.main }} />
					) : (
						<KeyboardArrowDownIcon sx={{ color: theme.palette.primary.main }} />
					)}
				</Box>
			</Box>
			<CustomizedDialog open={openDialog} onClose={handleClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader title={"Catalogs"} subtitle={"Select a catalog to use"} />
						<Box color={theme.palette.third.main}>
							<StepsIcon fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2} pt={1} pb={1}>
						<Grid container alignItems="center" justifyContent="center" >
							<Grid item xs={8}>
								<FormControl fullWidth>
									<InputLabel id="catalog" color="third">
                    Catalog
									</InputLabel>
									<Select
										labelId="catalog"
										id="catalog"
										value={selectedCatalog?._id || ""}
										label="Catalog"
										color="third"
										onChange={handleChangeCatalog}
									>
										{catalogs &&
                      catalogs.map((g) => (
                      	<MenuItem key={g._id} value={g._id}>
                      		{g.displayName}
                      	</MenuItem>
                      ))}
									</Select>
									<FormHelperText>Select a Catalog</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</CardBody>
					<CardFooter justifyContent={"end"} pb={1} px={2}>						
						<Button
							onClick={handleSetCollection}
							color="third"
							disabled={!isCatalogSelected ? true : false}
							startIcon={<Check />}
						>
                SAVE
						</Button>						
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
