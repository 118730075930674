import { Google } from "@mui/icons-material";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import Authentication from "auth/Authentication";
// import GoogleSignButton from "auth/components/GoogleSignInButton";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { toast } from "react-toastify";

const GoogleSignInButton = () => {
	const theme = useTheme();

	const handleSignIn = async () => {
		try {
			await Authentication.signInWithGoogle();
		} catch (error) {
			console.error("Error signing in with Google:", error);
			toast.error(error.message);
		}
	};

	return (
		<Button
			variant="outlined"
			size="large"
			onClick={handleSignIn}
			startIcon={<Google />}
			sx={{
				marginTop: "2rem",
				width: "350px",
				color: theme.palette.text.secondary,
				textTransform: "none",
				borderColor: "rgba(255, 255, 255, 0.23)",
				backgroundColor: "rgba(255, 255, 255, 0.05)",
				"&:hover": {
					backgroundColor: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
					color: "white",
				},
			}}
		>
      Sign In With Google
		</Button>
	);
};

export default function SignIn() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const handleUserChange = (user) => {								
			if (user) {
				navigate("/", { replace: true });							
			}
			setIsLoading(false);	
		};

		Authentication.onUserChange(handleUserChange);
		return () => {
			Authentication.offUserChange(handleUserChange);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (isLoading) {
		return <React.Fragment />;
	}

	return (
		<Box
			width="100%"
			height="100vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Card>
				<CardHeader justifyContent="center" py={2}>
					<img
						src="/images/company-logo-vertical-combined.png"
						alt="Radioactive Games"
						width="200px"
						height="auto"
					/>
				</CardHeader>
				<CardBody justifyContent="center" px={3} pb={3}>
					<Stack direction="column" spacing={2} alignItems="center">
						<Typography
							variant="h4"
							fontWeight="bold"
							textAlign="center"
							color={theme.palette.primary.main}
						>
              Hi, Welcome Back
						</Typography>
						<Typography
							variant="body1"
							textAlign="center"
							color="text.secondary"
						>
              Enter your credentials to continue
						</Typography>
						{/* Icon button */}
					</Stack>
					<GoogleSignInButton />
				</CardBody>
				<CardFooter justifyContent="center" py={2}>
					<Typography variant="body1" textAlign="center" color="text.secondary">
            © 2022 Radioactive Games
					</Typography>
				</CardFooter>
			</Card>
		</Box>
	);
}
