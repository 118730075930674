import { toast } from "react-toastify";
import ServiceBase from "./ServiceBase";

class UserService extends ServiceBase {
	async getAll({ params } = {}) {
		const url = "/admin/users";
		return await super.get(url, params);
	}

	async get({ id }) {
		const url = `/admin/users/${id}`;
		return await super.get(url, {});
	}

	async create({ data }) {
		const url = "/admin/users";
		const newItem = await super.post(url, data);
		if (newItem) {
			toast.success(`User ${newItem.displayName} added successfully`);
		}
		return newItem;
	}

	async update({ id, data }) {
		const url = `/admin/users/${id}`;
		const udpatedItem = await super.put(url, data);
		if (udpatedItem) {
			toast.success(`User ${udpatedItem.displayName} updated successfully`);
		}
		return udpatedItem;
	}

	async delete({ id }) {
		const url = `/admin/users/${id}`;
		const deletedItem = await super.delete(url);
		if (deletedItem) {
			toast.success(`User ${deletedItem.displayName} deleted successfully`);
		}
		return deletedItem;
	}
}

export default UserService;
