import { createSlice } from "@reduxjs/toolkit";

const initialStateGame = () => {
	try {
		return JSON.parse(sessionStorage.getItem("game")) || {};
	} catch (e) {
		return {};
	}
};

export const gameSlice = createSlice({
	name: "game",
	initialState: {
		game: initialStateGame(),
	},
	reducers: {
		setGame: (state, action) => {
			if (action.payload) {
				state.game = action.payload;
				sessionStorage.setItem("game", JSON.stringify(action.payload));
			} else {
				state.game = {};
				sessionStorage.removeItem("game");
			}
		},        
	},
});

export const { setGame } = gameSlice.actions;
export default gameSlice.reducer;