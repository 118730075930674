import { Logout, Palette, SettingsOutlined } from "@mui/icons-material";
import { alpha, Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import Authentication from "auth/Authentication";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { themeSlice } from "stores/slices/theme";

const HeaderMenu = ({ children }) => {
	return (
		<Box px={1.5} py={1}>
			<Typography
				fontWeight={500}
				fontSize={14}
				color="text.secondary"
				sx={{ 
					cursor: "default", 
					transition: "all 0.2s ease-in-out" 
				}}
			>
				{children}
			</Typography>
		</Box>
	);
};

export default function AccountMenu() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = Authentication;		
	const [ anchorEl, setAnchorEl ] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSignOut = () => {
		navigate("/auth/signout");
	};

	const setTheme = (theme) => {
		if(theme !== "light" && theme !== "dark") {theme = "light";}
		dispatch(themeSlice.actions.setTheme(theme));
	};

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
				{/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
				<Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
				<Tooltip title="Account settings">
					<IconButton
						onClick={handleClick}
						size="small"
						// sx={{ ml: 2 }}
						aria-controls={open ? "account-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						sx={{
							cursor: "pointer",
							color: theme.palette.secondary.main,
							backgroundColor: alpha("#fff", 0.07),
							borderRadius: 10,
							padding: 1,
							transition: "all 0.1s ease-in-out",
							"&:hover": {
								backgroundColor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							},
						}}
					>
						<Avatar
							sx={{ width: 32, height: 32, marginRight: "0.5rem" }}
							// src={user.imageUrl}
							alt={user.displayName}
							// imgProps={{ referrerPolicy: "no-referrer" }}
						>
							<img
								src={user.imageUrl}
								alt={user.displayName}
								width={"100%"}
								height={"100%"}
								referrerPolicy="no-referrer"
							/>
						</Avatar>
						<SettingsOutlined />
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						minWidth: 250,
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: "\"\"",
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<HeaderMenu>Account</HeaderMenu>
				<MenuItem>
					<Avatar
						src={user.imageUrl}
						alt={user.displayName}
						referrerPolicy="no-referrer"
					/>{" "}
					{user.displayName}
				</MenuItem>
				<HeaderMenu>Theme</HeaderMenu>
				<MenuItem onClick={() => setTheme("dark")}>
					<ListItemIcon>
						<Palette fontSize="small" />
					</ListItemIcon>
          Dark
				</MenuItem>
				<MenuItem onClick={() => setTheme("light")}>
					<ListItemIcon>
						<Palette fontSize="small" />
					</ListItemIcon>
          Light
				</MenuItem>
				<HeaderMenu>Setting</HeaderMenu>
				<MenuItem onClick={() => handleSignOut()}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
          Signout
				</MenuItem>
			</Menu>
		</>
	);
}
