import ServiceBase from "./ServiceBase";
import { toast } from "react-toastify";

class PlayerService extends ServiceBase {
    
	async getAll({ gameId, params= null }= {}) {		
		if(!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/players`;
		return await super.get(url, params);				
	}

	async get({ gameId, id }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/players/${id}`;
		return await super.get(url, {});
	}

	async create({ gameId, data }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/players`;
		const newItem = await super.post(url, data);
		if (newItem) {
			toast.success(`Player ${newItem.displayName} added successfully`);
		}
		return newItem;
	}

	async update({ gameId, id, data }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/players/${id}`;
		const udpatedItem = await super.put(url, data);
		if (udpatedItem) {
			toast.success(`Player ${udpatedItem.displayName} updated successfully`);
		}
		return udpatedItem;
	}

	async delete({ gameId, id }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/players/${id}`;
		const deletedItem = await super.delete(url);
		if (deletedItem) {
			toast.success(`Player ${deletedItem.displayName} deleted successfully`);
		}
		return deletedItem;
	}
}

export default PlayerService;
