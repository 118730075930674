import { SportsEsports } from "@mui/icons-material";
import GameService from "services/gameService";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Skeleton } from "@mui/material";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedAddButton, CustomizedBoxMessage, CustomizedDeleteButton, CustomizedHeader } from "components/Tools";
import CustomizedTable from "components/Table";
import FormDialogGames from "./FormDialogGames";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { gameSlice } from "stores/slices/game";
import { catalogSlice } from "stores/slices/catalog";

const columnsGamesTable = [
	{
		id: "avatar",
		label: "Avatar",
		type: "avatar",
		align: "center",
		borderRadious: "25%",
		default: <SportsEsports />,
		width: 10,
		disabledPadding: true,
	},
	{
		id: "displayName",
		id2: "_id",
		label: "Display Name",
		type: "text",
		align: "left",
		sortable: true,
	},
	{
		id: "status",
		label: "Status",
		type: "text",
		align: "left",
		sortable: true,
	},
];

export default function Games({
	setGame = () => {},
}) {	 	
	const queryClient = useQueryClient();
	const dispatch = useDispatch();	
  
	const gameService = new GameService();  

	const { game: mainGame } = useSelector(
		(state) => state.game
	);

	// STATES
	const [ openConfirmationDelete, setOpenConfirmationDelete ] = useState(false);
	const [ openFormDialog, setOpenFormDialog ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formMode, setFormMode ] = useState("");
	const [ selectedItems, setSelectedItems ] = useState([]);	

	const keys = ["games"];

	const { data, isLoading } = useQuery(
		keys,
		async () => await gameService.getAll(),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		}
	);

	// MUTATIONS

	const createMutation = useMutation(
		async (data) => await gameService.create(data),
		{
			onSuccess: (newItem) => {
				if (!newItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return [ ...oldItems, newItem ];
				});
			},
		}
	);

	const updateMutation = useMutation(
		async (data) => await gameService.update(data),
		{
			onSuccess: (updatedItem) => {
				if (!updatedItem) {
					return;
				}
				if (mainGame?._id === updatedItem._id) {
					dispatch(gameSlice.actions.setGame(updatedItem));
				}
				queryClient.setQueryData(keys, (oldItems) => {
					const index = oldItems.findIndex(
						(item) => item._id === updatedItem._id
					);
					oldItems[index] = updatedItem;
					return [...oldItems];
				});
			},
		}
	);

	const deleteMutation = useMutation(
		async (id) => await gameService.delete(id),
		{
			onSuccess: (deletedItem) => {
				if (!deletedItem) {
					return;
				}
				if (mainGame?._id === deletedItem._id) {
					dispatch(gameSlice.actions.setGame({}));
					dispatch(catalogSlice.actions.setCatalog({}));
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return oldItems.filter((item) => item._id !== deletedItem._id);
				});
			},
		}
	);

	// HANLDES
	const handleAdd = () => {   
		setFormMode("create");
		setFormData({});
		setOpenFormDialog(true);
	};

	const handleEdit = (id) => {
		setFormMode("update");
		const item = data?.find((item) => item._id === id);
		setFormData(item);
		setOpenFormDialog(true);
	};

	const handleDelete = (id) => {
		setFormMode("delete");
		const item = data?.find((item) => item._id === id);
		setFormData(item);  
		setOpenConfirmationDelete(true);
	};

	const hanldeDeleteSelected = () => {
		setFormMode("deleteSelected");
		setOpenConfirmationDelete(true);
	};

	const onSubmit = async (data) => {
		if (formMode === "create") {
			// setFormData(data); // No es necesario      
			await createMutation.mutateAsync({ data });
		} 
		if (formMode === "update") {      
			// setFormData(data);  // No es necesario
			await updateMutation.mutateAsync({ id: data._id, data });
		}
		if (formMode === "delete") {
			await deleteMutation.mutateAsync({ id: formData._id });
		}
		if(formMode === "deleteSelected"){
			selectedItems.forEach(async (id) => {
				await deleteMutation.mutateAsync({ id });
			});
			setSelectedItems([]);
		}
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);
	};  

	const onClose = () => {
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);
	};

	useEffect(() => {
		if(selectedItems.length === 1){
			setGame(data?.find((item) => item._id === selectedItems[0]));
		} else {
			setGame(null);
		}
	}, [ selectedItems, setGame, data ]);

	if (isLoading) {
		return <Skeleton variant="rounded" height={250} />;
	}

	return (
		<>
			{/* TABLE OF GAMES */}
			<Card>
				<CardHeader p={2} py={1} justifyContent={"space-between"}>
					<CustomizedHeader
						title={"List of Games"}
						subtitle={"Manage your games"}
					/>
					<Box display="flex">
						<Box mr={0.75}>
							{selectedItems.length > 0 && (
								<CustomizedDeleteButton onClick={hanldeDeleteSelected} />
							)}
						</Box>
						<CustomizedAddButton onClick={handleAdd} />
					</Box>
				</CardHeader>
				<CardBody maxHeight="24.5rem">
					{data && data.length > 0 && (
						<CustomizedTable
							itemKey="_id"
							onRowClickSelect
							selectabledRows
							selected={selectedItems}
							setSelected={setSelectedItems}
							columns={columnsGamesTable}
							rows={data}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					)}
					{data && data.length === 0 && (
						<CustomizedBoxMessage message={"Games not found"} />
					)}
				</CardBody>
				{data && data.length > 0 && (
					<CardFooter px={2} py={1} borderColor="#525252">
						{selectedItems.length > 0 &&
              `Selected ${selectedItems.length} of ${data.length}`}
						{selectedItems.length === 0 && `Total ${data.length}`}
					</CardFooter>
				)}
			</Card>
			{/* fORM OF GAMES */}
			<FormDialogGames
				isOpen={openFormDialog}
				onClose={onClose}
				onSubmit={onSubmit}
				data={formData}
				setData={setFormData}
				isEdit={formMode === "update"}
			/>
			{/* CONFIRM DELETE MESSAGE */}
			<ConfirmationDialog
				title={
					formMode === "delete"
						? `You want to delete ${formData.displayName} game?`
						: `You want to delete these ${selectedItems.length} games?`
				}
				subtitle={
					formMode === "delete"
						? "Are you sure you want to delete this game?"
						: `Are you sure you want to delete these ${selectedItems.length} games?`
				}
				message={
					formMode === "delete"
						? "This action can not be undone. If you delete this game, all data associated with it will be lost."
						: `This action can not be undone. If you delete these ${selectedItems.length} games, all data associated with it will be lost.`
				}
				open={openConfirmationDelete}
				onClose={onClose}
				onConfirm={onSubmit}
			/>
		</>
	);
}
