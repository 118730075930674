import { Check, Close, Person } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedHeader } from "components/Tools";
import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomizedDialog from "components/Dialog";

export default function FormDialogInvitations({
	data = {},	
	onClose = () => {},
	onSubmit,
	isEdit = false,
	isOpen = false,
}) {
	const theme = useTheme();
	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formErrors, setFormErrors ] = useState({});

	const handleOnChange = (e) => {
		const { name, value } = e.target;	    	
		const newData = { ...formData, [name]: value };
		setFormData(newData);
		validateForm(newData);
	};

	const handleOnChangeRole = (e) => {
		const { value } = e.target;
		const newData = { ...formData, role: value };
		setFormData(newData);
		validateForm(newData);
	};

	const handleOnChangeStatus = (e) => {
		const { value } = e.target;
		const newData = { ...formData, status: value };
		setFormData(newData);
		validateForm(newData);
	};

	const validateForm = (newData) => {    
		const { email, status, role } = newData;   
		const newErrors = formErrors;
		const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
		let isValid = true;						
		if (!email || !emailRegex.test(email)) {
			newErrors.email = "Email is required or invalid";		
			isValid = false;	
		} else {
			newErrors.email = "";			
		}
		if(isEdit) {
			if(!status) {
				newErrors.status = "Status is required";
				isValid = false;
			}else {
				newErrors.status = "";			
			}
			if(!role) {
				newErrors.role = "Role is required";
				isValid = false;
			}else {
				newErrors.role = "";			
			}
		}    
		setFormErrors(newErrors);    
		setIsFormValid(isValid);
	};    

	const handleSubmit = (e) => {
		e.preventDefault();
		if(!isFormValid) {
			return;
		}
		onSubmit(formData);
	};

	useEffect(() => {
		if(isOpen) {
			setFormData(data);
			setFormErrors({});
			setIsFormValid(false);
			setTimeout(() => {
				document.getElementById("invitationEmail").focus();
			}, 100);
		}
	}, [ data, isOpen ]);

	return (
		<>
			<CustomizedDialog open={isOpen} onClose={onClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader
							title={isEdit ? "Edit Invitation" : "Add Invitation"}
							subtitle={
								isEdit ? "Edit invitation details" : "Add a new invitation"
							}
						/>
						<Box color={theme.palette.third.main}>
							<Person fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2} py={1}>
						<Box component={"form"} onSubmit={handleSubmit}>
							<Grid container rowSpacing={1.5} columnSpacing={1.5}>
								<Grid item xs={12} pb={1.5}>
									<FormLabel component="legend">PROPERTIES</FormLabel>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="invitationEmail"
										name="email"
										label="Email"
										type="email"
										helperText={
											formErrors["email"] || "Enter the invitation email"
										}
										fullWidth
										required
										autoFocus
										color={"third"}
										autoComplete="off"
										// inputProps={{}}
										onChange={handleOnChange}
										value={formData["email"] || ""}
										error={!!formErrors["email"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl required fullWidth>
										<InputLabel id="invitationRoleLabel" color={"third"}>
                      Role
										</InputLabel>
										<Select
											labelId="invitationRoleLabel"
											id="role"
											value={formData.role || ""}
											label="Role *"
											onChange={handleOnChangeRole}
											color={"third"}
										>
											<MenuItem value={"admin"}>Admin</MenuItem>
											<MenuItem value={"user"}>User</MenuItem>
											<MenuItem value={"guest"}>Guest</MenuItem>
										</Select>
										<FormHelperText>
											{formErrors["role"] || "Enter the invitation status"}
										</FormHelperText>
									</FormControl>
								</Grid>
								{isEdit && (
									<Grid item xs={12}>
										<FormControl required fullWidth>
											<InputLabel id="invitationStatusLabel" color={"third"}>
                        Status
											</InputLabel>
											<Select
												labelId="invitationStatusLabel"
												id="status"
												value={formData.status || ""}
												label="Status *"
												onChange={handleOnChangeStatus}
												color={"third"}
											>
												<MenuItem value={"pending"}>Pending</MenuItem>
												<MenuItem value={"accepted"}>Accepted</MenuItem>
												<MenuItem value={"rejected"}>Rejected</MenuItem>
											</Select>
											<FormHelperText>
												{formErrors["status"] || "Enter the invitation status"}
											</FormHelperText>
										</FormControl>
									</Grid>
								)}
							</Grid>
						</Box>
					</CardBody>
					<CardFooter justifyContent={"space-between"} p={1} px={2}>
						{/* Cancel Button */}
						<Button
							onClick={onClose}
							variant="text"
							color="third"
							startIcon={<Close />}
						>
              CANCEL
						</Button>
						{/* Save Button */}
						<Button
							onClick={handleSubmit}
							disabled={!isFormValid}
							color="third"
							startIcon={<Check />}
						>
              SAVE
						</Button>
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
