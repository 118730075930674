import PlayerService from "services/playerService";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Skeleton } from "@mui/material";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedAddButton, CustomizedBoxMessage, CustomizedDeleteButton, CustomizedHeader } from "components/Tools";
import CustomizedTable from "components/Table";
import FormDialogPlayers from "./FormDialogPlayers";
import ConfirmationDialog from "components/ConfirmationDialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Person } from "@mui/icons-material";

const columnsPlayersTable = [
	{
		id: "imageUrl",
		label: "Avatar",
		type: "avatar",
		align: "center",
		borderRadious: "25%",
		default: <Person />,
		width: 10,
		disabledPadding: true,
	},
	{
		id: "displayName",
		id2: "_id",
		label: "Display Name",
		type: "text",
		align: "left",
		sortable: true,
	},
	{
		id: "isVerified",
		label: "Verified",
		type: "boolean",
		align: "left",
		sortable: true,
		// width: 200,
	},
	{
		id: "status",
		label: "Status",
		type: "text",
		align: "left",
		sortable: true,
		// width: 200,
	},
	{
		id: "lastSession",
		label: "Last Session",
		type: "date",
		align: "left",
		sortable: true,
		// width: 200,
	},
];

export default function Players() {
	const queryClient = useQueryClient();

	const playerService = new PlayerService();

	const { game } = useSelector((state) => state.game);

	// STATES
	const [ openConfirmationDelete, setOpenConfirmationDelete ] = useState(false);
	const [ openFormDialog, setOpenFormDialog ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formMode, setFormMode ] = useState("");
	const [ selectedItems, setSelectedItems ] = useState([]);	

	const keys = [ "players", game?._id ];
	const enabled = game?._id ? true : false;

	const { data, isLoading } = useQuery(
		keys,
		async () => await playerService.getAll({ gameId: game?._id }),
		{
			enabled: enabled,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		}
	);

	// MUTATIONS
	const createMutation = useMutation(
		async (data) => await playerService.create(data),
		{
			onSuccess: (newItem) => {
				if (!newItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return [ ...oldItems, newItem ];
				});
			},
		}
	);

	const updateMutation = useMutation(
		async (data) => await playerService.update(data),
		{
			onSuccess: (updatedItem) => {
				if (!updatedItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					const index = oldItems.findIndex(
						(item) => item._id === updatedItem._id
					);
					oldItems[index] = updatedItem;
					return [...oldItems];
				});
			},
		}
	);

	const deleteMutation = useMutation(
		async (id) => await playerService.delete(id),
		{
			onSuccess: (deletedItem) => {
				if (!deletedItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return oldItems.filter(
						(item) => item._id !== deletedItem._id
					);
				});
			},
		}
	);

	// HANLDES
	const handleAdd = () => {
		if (!enabled) {
			toast.error("You must select a game first");
			return;
		}
		setFormMode("create");
		setFormData({ status: "active" });
		setOpenFormDialog(true);
	};

	const handleEdit = (id) => {
		if (!enabled) {
			toast.error("You must select a game first");
			return;
		}
		setFormMode("update");
		const item = data?.find((item) => item._id === id);
		setFormData(item);
		setOpenFormDialog(true);
	};

	const handleDelete = (id) => {
		if (!enabled) {
			toast.error("You must select a game first");
			return;
		}
		setFormMode("delete");
		const item = data?.find((item) => item._id === id);
		setFormData(item);
		setOpenConfirmationDelete(true);
	};

	const hanldeDeleteSelected = () => {    
		setFormMode("deleteSelected");
		setOpenConfirmationDelete(true);
	};

	const onSubmit = async (data) => {
		if (formMode === "create") {
			// setFormData(data); // No es necesario
			await createMutation.mutateAsync({
				gameId: game?._id,
				data,
			});
		}
		if (formMode === "update") {
			// setFormData(data);  // No es necesario
			await updateMutation.mutateAsync({
				gameId: game?._id,
				id: data._id,
				data,
			});
		}
		if (formMode === "delete") {
			await deleteMutation.mutateAsync({
				gameId: game?._id,
				id: formData._id,
			});
		}
		if (formMode === "deleteSelected") {
			selectedItems.forEach(async (id) => {
				await deleteMutation.mutateAsync({
					gameId: game?._id,
					id,
				});
			});
			setSelectedItems([]);
		}
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);
	};

	const onClose = () => {
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);		
		setFormData({});
	};

	if (isLoading) {
		return <Skeleton variant="rounded" height={250} />;
	}

	return (
		<>
			{/* TABLE OF GAMES */}
			<Card>
				<CardHeader p={2} py={1} justifyContent={"space-between"}>
					<CustomizedHeader
						title={"List of Players"}
						subtitle={"Manage your players"}
					/>
					<Box display="flex">
						<Box mr={0.75}>
							{selectedItems.length > 0 && (
								<CustomizedDeleteButton onClick={hanldeDeleteSelected} />
							)}
						</Box>
						<CustomizedAddButton onClick={handleAdd} />
					</Box>
				</CardHeader>
				<CardBody maxHeight="24.5rem">
					{data && data.length > 0 && (
						<CustomizedTable
							itemKey="_id"
							defaultOrderBy="displayName"
							selectabledRows
							selected={selectedItems}
							setSelected={setSelectedItems}
							columns={columnsPlayersTable}
							rows={data}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					)}
					{data && data.length === 0 && (
						<CustomizedBoxMessage message={"Players not found"} />
					)}
					{!enabled && (
						<CustomizedBoxMessage
							message={"First select a game"}
						/>
					)}
				</CardBody>
				{data && data.length > 0 && (
					<CardFooter px={2} py={1} borderColor="#525252">
						{selectedItems.length > 0 &&
              `Selected ${selectedItems.length} of ${data.length}`}
						{selectedItems.length === 0 && `Total ${data.length}`}
					</CardFooter>
				)}
			</Card>
			{/* fORM OF Players */}
			<FormDialogPlayers
				isOpen={openFormDialog}
				onClose={onClose}
				onSubmit={onSubmit}
				data={formData}
				setData={setFormData}
				isEdit={formMode === "update"}
			/>
			{/* CONFIRM DELETE MESSAGE */}
			<ConfirmationDialog
				title={
					formMode === "delete"
						? `You want to delete ${formData.displayName} player?`
						: `You want to delete these ${selectedItems.length} players?`
				}
				subtitle={
					formMode === "delete"
						? "Are you sure you want to delete this player?"
						: `Are you sure you want to delete these ${selectedItems.length} players?`
				}
				message={
					formMode === "delete"
						? "This action can not be undone. If you delete this player, all data associated with it will be lost."
						: `This action can not be undone. If you delete these ${selectedItems.length} players, all data associated with it will be lost.`
				}
				open={openConfirmationDelete}
				onClose={onClose}
				onConfirm={onSubmit}
			/>
		</>
	);
}
