import {
	Email,	
	EuroSymbolOutlined,	
	GroupOutlined,
	SettingsOutlined,	
	SportsEsportsOutlined,
} from "@mui/icons-material";
import { Box, Icon, Typography, useTheme } from "@mui/material";
import { alpha, Stack } from "@mui/system";
import Authentication from "auth/Authentication";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedDrawer from "./Drawer";

const HeaderMenu = ({ open, children }) => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent={open ? "flex-start" : "center"}
			px={open ? 0.5 : 1.5}
			width={open ? 215 : "auto"}
			height={45}
			minHeight={45}
		>
			<Typography
				fontWeight={500}
				fontSize={14}
				// paddingLeft={1.25}
				// width="100%"
				color="text.secondary"
				sx={{ 
					cursor: "default",
					transition: "all 0.2s ease-in-out"
				}}
				borderRadius={50}		
			>
				{open ? children : children[0]}
			</Typography>
		</Box>
	);
};

const ItemMenu = ({ open, active, icon, title, onClick }) => {    
	const theme = useTheme();
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="flex-start"
			px={1.5}
			pl={open ? 2.5 : 1.5}
			width={open ? 215 : "auto"}
			height={45}
			minHeight={45}
			sx={{
				borderRadius: theme.shape.borderRadius,
				cursor: "pointer",
				backgroundColor: active
					? alpha(theme.palette.secondary.main, 0.1)
					: "transparent",
				color: active ? theme.palette.secondary.main : "text.secondary",
				"&:hover": {
					backgroundColor: alpha(theme.palette.secondary.main, 0.1),
					color: theme.palette.secondary.main,
				},
				transition: "all 0.1s ease-in-out",
			}}
			onClick={onClick}
		>
			<Icon
				component={icon}
				fontSize={open ? "small" : "medium"}
				sx={{
					color: active ? theme.palette.secondary.main : "text.secondary",
				}}
			/>
			{open && (
				<Typography
					fontWeight={400}
					fontSize={14}
					paddingLeft={2}				
				// display={open ? "block" : "none"}
				>
					{title}
				</Typography>
			)}
		</Box>
	);
};

export default function Sidebar() {	
	const navigate = useNavigate();
	const location = useLocation();	

	const { user } = Authentication;	
	const { game } = useSelector((state) => state.game);

	const isGame = game && game._id ? true : false;

	const { open } = useSelector((state) => state.appbar);
	return (
		<CustomizedDrawer>
			<Stack direction="column" spacing={0.5} height="100%">
				<HeaderMenu open={open}>Data</HeaderMenu>
				<ItemMenu
					open={open}
					icon={SportsEsportsOutlined}
					title="Games"
					active={location.pathname === "/app/data/games"}
					onClick={() => navigate("/app/data/games")}
				/>
				{isGame && (
					<>
						{/* PLAYERS */}
						<ItemMenu
							open={open}
							icon={GroupOutlined}
							title="Players"
							active={location.pathname === "/app/data/players"}
							onClick={() => navigate("/app/data/players")}
						/>
						{/* ECNONOMY */}
						<ItemMenu
							open={open}
							icon={EuroSymbolOutlined}
							title="Economy"
							active={location.pathname.startsWith("/app/data/economy")}
							onClick={() => navigate("/app/data/economy")}
						/>
						{user.role === "admin" && (
							<>
								{/* CONFIG */}
								<ItemMenu
									open={open}
									icon={SettingsOutlined}
									title="Settings"
									active={location.pathname === "/app/data/settings"}
									onClick={() => navigate("/app/data/setting")}
								/>
							</>
						)}
					</>
				)}
				{user.role === "admin" && (
					<>
						<HeaderMenu open={open}>Admin</HeaderMenu>
						{/* USERS */}
						<ItemMenu
							open={open}
							icon={GroupOutlined}
							title="Users"
							active={location.pathname === "/app/admin/users"}
							onClick={() => navigate("/app/admin/users")}
						/>
						{/* INVITATIONS */}
						<ItemMenu
							open={open}
							icon={Email}
							title="Invitations"
							active={location.pathname === "/app/admin/invitations"}
							onClick={() => navigate("/app/admin/invitations")}
						/>
					</>
				)}
			</Stack>
		</CustomizedDrawer>
	);
}
