import Axios from "axios";

const axios = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	responseType: "json",
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
		"X-radioactive-api-key": process.env.REACT_APP_API_KEY,
	},
});

export default axios;