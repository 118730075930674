import {
	MonetizationOn,	
	Store,
	LocalOffer,	
	QrCode,	
	Style,	
	ArrowBackIosNew,
} from "@mui/icons-material";
import {	
	Grid,
	IconButton,	
	Tab,
	Tabs,
	Tooltip,
} from "@mui/material";
import { Card, CardBody, CardHeader } from "components/Card";

import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Index() {	
	const navigate = useNavigate();
	const [ activeTab, setActiveTab ] = useState("items");

	const { catalog } = useSelector((state) => state.catalog);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const LazyItems = React.lazy(() => import("./Items"));  	
	const LazyBundles = React.lazy(() => import("./Bundles"));
	const LazyCurrencies = React.lazy(() => import("./Currencies"));
	const LazyStores = React.lazy(() => import("./Stores"));		
	const LazyCodes = React.lazy(() => import("./Codes"));

	useEffect(() => {		
		if (!catalog || !catalog._id) {
			navigate("/app/data/economy");
		}
	}, [ catalog, navigate ]);
  

	return (
		<>
			<Grid container spacing={2}>
				{/* TABLE OF GAMES */}
				<Grid item xs={12}>
					<Card>
						<CardHeader pt={1} px={2} display="flex" alignItems="center">
							<Tooltip title="Back to Catalogs">
								<IconButton onClick={() => navigate("/app/data/economy")}>
									<ArrowBackIosNew />
								</IconButton>
							</Tooltip>
							<Tabs value={activeTab} onChange={handleTabChange}>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Style style={{ fontSize: 20, marginRight: 5 }} />
                      Items
										</div>
									}
									value={"items"}
								/>
								{/* <Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Equalizer style={{ fontSize: 20, marginRight: 5 }} />
                      Drop Tables
										</div>
									}
									value={"dropTables"}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Archive style={{ fontSize: 20, marginRight: 5 }} />
                      Containers
										</div>
									}
									value={"containers"}
								/> */}
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<LocalOffer style={{ fontSize: 20, marginRight: 5 }} />
                      Bundles
										</div>
									}
									value={"bundles"}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<MonetizationOn
												style={{ fontSize: 20, marginRight: 5 }}
											/>
                      Currencies
										</div>
									}
									value={"currencies"}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Store style={{ fontSize: 20, marginRight: 5 }} />
                      Stores
										</div>
									}
									value={"stores"}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<QrCode style={{ fontSize: 20, marginRight: 5 }} />
                      Codes
										</div>
									}
									value={"codes"}
								/>
							</Tabs>
						</CardHeader>
						<CardBody maxHeight="49.5rem" height="49.5rem" px={2}>
							<Suspense fallback={<div>Loading...</div>}>
								{activeTab === "items" && <LazyItems />}
								{activeTab === "bundles" && <LazyBundles />}
								{activeTab === "stores" && <LazyStores />}
								{activeTab === "currencies" && <LazyCurrencies />}
								{activeTab === "codes" && <LazyCodes />}
							</Suspense>
						</CardBody>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
