import { toast } from "react-toastify";
import ServiceBase from "./ServiceBase";

class GameService extends ServiceBase {  
	async getAll({ params } = {}) {
		const url = "/data/games";
		const games = await super.get(url, params);		
		return games;
	}

	async get({ id }) {
		const url = `/data/games/${id}`;
		return await super.get(url, {});
	}

	async create({ data }) {
		const url = "/data/games";
		const newItem = await super.post(url, data);
		if (newItem) {
			toast.success(`Game ${newItem.displayName} added successfully`);
		}
		return newItem;
	}

	async update({ id, data }) {
		const url = `/data/games/${id}`;
		const udpatedItem = await super.put(url, data);
		if (udpatedItem) {
			toast.success(`Game ${udpatedItem.displayName} updated successfully`);
		}
		return udpatedItem;
	}

	async delete({ id }) {
		const url = `/data/games/${id}`;
		const deletedItem = await super.delete(url);
		if (deletedItem) {
			toast.success(`Game ${deletedItem.displayName} deleted successfully`);
		}
		return deletedItem;
	}

	async updateStatus({ id, status }) {
		const url = `/data/games/${id}/status`;
		const udpatedItem = await super.patch(url, { status });
		if (udpatedItem) {
			toast.success(`Game status ${udpatedItem.status} updated successfully`);
		}
		return udpatedItem;
	}
}

export default GameService;
