import { toast } from "react-toastify";
import axios from "utils/Axios";

class ServiceBase {	
	async get(url, params = null) {
		try {			
			const response = await axios.get(url, { params });
			const message = response.data;
			if (!message.success) {
				toast.error(message.message);
				return false;
			}          			
			return message.data;
		} catch (error) {      
			if (error.name !== "CanceledError") {				
				const message = error.response?.data?.error?.message;
				console.log(error);
				toast.error(message? message:"Error getting items");
			}
			return false;
		}
	}

	async post(url, item) {
		try {			
			const response = await axios.post(url, item);
			const message = response.data;
			if (!message.success) {
				toast.error(message.message);
				return false;
			}
			return message.data;
		} catch (error) {
			if (error.name !== "CanceledError") {
				const message = error.response?.data?.error?.message;
				console.log(error);
				toast.error(message ? message : "Error getting items");
			}
			return false;
		}
	}

	async put(url, item) {
		try {			
			const response = await axios.put(url, item);
			const message = response.data;
			if (!message.success) {
				toast.error(message.message);
				return false;
			}
			return message.data;
		} catch (error) {
			if (error.name !== "CanceledError") {
				const message = error.response?.data?.error?.message;
				console.log(error);
				toast.error(message ? message : "Error getting items");
			}
			return false;
		}
	}

	async patch(url, item) {
		try {
			const response = await axios.patch(url, item);
			const message = response.data;
			if (!message.success) {
				toast.error(message.message);
				return false;
			}
			return message.data;
		} catch (error) {
			if (error.name !== "CanceledError") {
				const message = error.response?.data?.error?.message;
				console.log(error);
				toast.error(message ? message : "Error getting items");
			}
			return false;
		}
	}

	async delete(url) {
		try {			
			const response = await axios.delete(url);
			const message = response.data;
			if (!message.success) {
				toast.error(message.message);
				return false;
			}
			return message.data || true;
		} catch (error) {
			if (error.name !== "CanceledError") {
				const message = error.response?.data?.error?.message;
				console.log(error);
				toast.error(message ? message : "Error getting items");
			}
			return false;
		}
	}
}

export default ServiceBase;