const { createTheme } = require("@mui/material/styles");

export default createTheme({
	palette: {
		mode: "light",
		primary: {
			light: "#f9aeae",
			main: "#FF0C4A",
			dark: "#af0d35",
			contrastText: "#fff",
		},
		third: {
			light: "#8EC3F2",
			main: "#1e88e5",
			dark: "#155fa0",
			contrastText: "#fff",
		},
		secondary: {
			light: "#c7b3ff",
			main: "#7C4DFF",
			dark: "#5635b2",
			contrastText: "#fff",
		},
		text: {
			primary: "#2a2a2a",
			secondary: "#6b6b6b",
			disabled: "rgba(0,0,0,0.38)",
			hint: "rgba(0,0,0,0.38)",
			breadcrumb: "#5D6D7E",
		},
		error: {
			light: "#f9aeae",
			main: "#FF0C4A",
			dark: "#af0d35",
			contrastText: "#fff",	
		},
		success: {
			light: "#35df90",
			main: "#1fb971",
			dark: "#178d56",
		},
		warning: {
			light: "#fac661",
			main: "#f0ae2d",
			dark: "#e39708",
		},
		background: {
			default: "#F4F6F8",
			paper: "#FFFFFF",
			paperLight: "#F9FAFC",
			paperDark: "#ECEFF1",
		},
	},
	shape: { borderRadius: 3 },
	typography: {
		fontFamily: "Roboto",
		h2: {
			fontWeight: 600,
		},
	},
});
