import { alpha, Box, IconButton, Stack, useTheme } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";
import { appbarSlice } from "stores/slices/appbar";
import { useLocation, useNavigate } from "react-router-dom";
// import GameSelector from "./GameSelector";
import AccountMenu from "./AccountMenu";
import GameSelector from "./GameSelector";
import CatalogSelector from "./CatalogSelector";

// Logo and toggle button for the appbar drawer.
const AppHeader = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleToggle = () => dispatch(appbarSlice.actions.toggleSidebar());

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				height="100%"
				sx={{ cursor: "pointer" }}
				onClick={() => navigate("/app")}
			>
				<img
					src="/images/company-logo-horizontal-combined.png"
					alt="Radioactive Games"
					width="150px"
					height="auto"
				/>
			</Box>
			<IconButton
				size="large"
				edge="start"
				aria-label="open drawer"
				onClick={handleToggle}
				sx={{
					color: theme.palette.primary.main,
					borderRadius: theme.shape.borderRadius,
					padding: 0.5,
					backgroundColor: alpha("#fff", 0.05),
					transition: "all 0.1s ease-in-out",
					"&:hover": {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					},
				}}
			>
				<MenuIcon />
			</IconButton>
		</>
	);
};

export default function AppBar() {	
	const theme = useTheme();
	const location = useLocation();	
	return (
	// Reserving space for the sidebar
		<Box minHeight={80} height={80}>
			{/* AppBar fixed position */}
			<Box
				display="flex"
				position="fixed"
				minHeight={80}
				height={80}
				width={"100%"}
				alignItems="center"
				backgroundColor={theme.palette.background.default}
				// backgroundColor={"transparent"}
				sx={{ zIndex: 10 }}
			>
				<Box
					width={240}
					minWidth={240}
					maxWidth={240}
					height="100%"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					pl={2}
					pr={1}
				>
					<AppHeader />
				</Box>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					pl={1}
					pr={2}
				>
					<Stack direction="row" spacing={1}>
						<GameSelector />
						{location.pathname === "/app/data/economy/catalogs" && <CatalogSelector />}
					</Stack>
					<AccountMenu />
				</Box>
			</Box>
		</Box>
	);
}
