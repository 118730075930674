import { Grid } from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";

import React from "react";
import Items from "./Players";

export default function Index() {
	return (
		<>
			<Grid container spacing={2}>
				{/* BREADCRUMBS */}
				<Grid item xs={12}>
					<Breadcrumbs />
				</Grid>
				{/* TABLE OF Items */}
				<Grid item xs={12}>
					<Items />
				</Grid>

			</Grid>
		</>
	);
}
