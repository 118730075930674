import { Avatar, Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { 
	Add as AddIcon, 
	Edit as EditIcon,
	Delete as DeleteIcon,
	Search, 
	Email
} from "@mui/icons-material";

const CustomizedHeader = ({ title, subtitle, icon }) => {
	const theme = useTheme();
	return (
		<Box display="flex" alignItems="center">
			<Box display={icon?"flex":"none"} mr={icon ? 1.5 : 0} color={theme.palette.secondary.main}>
				<Avatar
					sx={{
						bgcolor: theme.palette.secondary.main,
						color: theme.palette.secondary.contrastText,
					}}
				>
					{/* <ItemIcon /> */}
					{icon}
				</Avatar>
			</Box>
			<Box>
				<Typography variant="h6">{title}</Typography>
				<Typography
					variant="subtitle2"
					sx={{ color: theme.palette.text.secondary }}
				>
					{subtitle}
				</Typography>
			</Box>
		</Box>
	);
};

const CustomizedSearchButton = ({ onClick = () => {} }) => {
	const theme = useTheme();
	return (
		<Tooltip title={"Search"}>
			<IconButton
				onClick={onClick}
				sx={{
					padding: 1,
					backgroundColor: "transparent",
					"&:hover": {
						backgroundColor: theme.palette.grey[700],
					},
				}}
			>
				<Search />
			</IconButton>
		</Tooltip>
	);
};

const CustomizedSendButton = ({ onClick = () => {} }) => {
	const theme = useTheme();
	return (
		<Tooltip title={"Send"}>
			<IconButton
				onClick={onClick}
				sx={{
					padding: 1,
					backgroundColor: "transparent",
					"&:hover": {
						backgroundColor: theme.palette.grey[700],
					},
				}}
			>
				<Email />
			</IconButton>
		</Tooltip>
	);
};

const CustomizedEditButton = ({ onClick = () => {} }) => {
	const theme = useTheme();
	return (
		<Tooltip title={"Edit"}>
			<IconButton
				onClick={onClick}
				sx={{
					padding: 1,
					backgroundColor: "transparent",
					"&:hover": {
						backgroundColor: theme.palette.grey[700],
					},
				}}
			>
				<EditIcon />
			</IconButton>
		</Tooltip>
	);
};

const CustomizedDeleteButton = ({ onClick = () => {}, title="Delete selected" }) => {	
	return (
		<Tooltip title={title}>
			<IconButton
				onClick={onClick}
				sx={{					
					backgroundColor: "transparent",
					"&:hover": {
						color: "red",
						backgroundColor: "transparent"
					},
				}}
			>
				<DeleteIcon
					sx={{						
						color: "grey.500",
						"&:hover": { 						
							color: "red" },
					}}
				/>
			</IconButton>
		</Tooltip>
	);
};

const CustomizedAddButton = ({ title = "Add", onClick = () => {} }) => {
	const theme = useTheme();
	return (
		<Tooltip title={title}>
			<IconButton
				onClick={onClick}
				sx={{
					padding: 1,
					backgroundColor: theme.palette.third.main,
					"&:hover": {
						backgroundColor: theme.palette.third.dark,
					},
				}}
			>
				<AddIcon />
			</IconButton>
		</Tooltip>
	);
};

const CustomizedBoxMessage = ({ message, ...props }) => {	
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			p={2}
			{...props}
		>
			<Typography variant="h6" color="textSecondary">
				{message}
			</Typography>
		</Box>
	);
};

const FormatValue = (value, formats) => {
	if (formats) {
		const { trim, lowercase, uppercase } = formats;
		let newValue = value;
		if (trim) {
			newValue = newValue.trim();
		}
		if (lowercase) {
			newValue = newValue.toLowerCase();
		}
		if (uppercase) {
			newValue = newValue.toUpperCase();
		}
		return newValue;
	}
	return value;
};

const ValidateRequires = (values = {}, requires = []) => {    
	let isValid = true;
	requires.forEach((require) => {
		if (
			!values[require] ||
            values[require] === undefined || 
            values[require].length === 0
		) {
			isValid = false;
			return { isValid };
		}
	});	
	return { isValid };
};

const ValidateValue = (value, validations) => {
	if (validations) {
		const {
			required,
			minLength,
			maxLength,
			min,
			max,
			isNumber,
			isLong,
			isObject,
			isJson,
			isPositive,
			pattern,
		} = validations;
		let isValid = true;
		let error = "";
		if (required) {
			isValid = isValid && value.trim() !== "";
			error = isValid ? "" : "This field is required";
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (minLength) {
			isValid = isValid && value.length >= minLength;
			error = isValid
				? ""
				: `This field must be at least ${minLength} characters`;
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (maxLength) {
			isValid = isValid && value.length <= maxLength;
			error = isValid
				? ""
				: `This field must be at most ${maxLength} characters`;
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (min) {
			isValid = isValid && value >= min;
			error = isValid ? "" : `This field must be at least ${min}`;
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (max) {
			isValid = isValid && value <= max;
			error = isValid ? "" : `This field must be at most ${max}`;
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (isPositive) {
			isValid = isValid && value >= 0;
			error = isValid ? "" : "This field must be positive";
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (isNumber) {
			isValid = isValid && !isNaN(value);
			error = isValid ? "" : "This field must be a number";
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (isLong) {
			isValid = isValid && !isNaN(value) && value % 1 === 0;
			error = isValid ? "" : "This field must be a long";
			if (!isValid) {
				return { isValid, error };
			}
		}   
		if (isObject) {
			isValid = isValid && typeof value === "object";
			error = isValid ? "" : "This field must be an object";
			if (!isValid) {
				return { isValid, error };
			}
		}
		if (isJson) {
			if(required){
				try {
					JSON.parse(value);
					isValid = true;
				} catch (e) {
					isValid = false;
				}
				error = isValid ? "" : "This field must be a json";
				if (!isValid) {
					return { isValid, error };
				}
			}else{
				if(value && value.length > 0){
					try {
						JSON.parse(value);
						isValid = true;
					} catch (e) {
						isValid = false;
					}
					error = isValid ? "" : "This field must be a json";
					if (!isValid) {
						return { isValid, error };
					}
				}
			}
		}
		if (pattern) {
			isValid = isValid && pattern.test(value);
			error = isValid ? "" : "This field is invalid";
			if (!isValid) {
				return { isValid, error };
			}
		}
		return { isValid, error };
	}
	return { isValid: true, error: "" };
};


export {
	CustomizedHeader,
	CustomizedAddButton,
	CustomizedEditButton,
	CustomizedDeleteButton,
	CustomizedSendButton,
	CustomizedBoxMessage,
	CustomizedSearchButton,		
	ValidateValue,
	ValidateRequires,
	FormatValue,
};
