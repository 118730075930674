import { Box, LinearProgress } from "@mui/material";
import React from "react";

export default function Loading() {
	return (
		<>
			<Box sx={{ width: "100%" }}>
				<LinearProgress color="secondary" />
			</Box>
			{/* <Box
				sx={{
					width: "100%",
					height: "calc(100vh - 1rem)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>				
				<img
					src="/images/company-logo-vertical-combined.png"
					alt="Radioactive Games"
					width="280px"
					height="auto"
				/>				
			</Box> */}
		</>
	);
}
