import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "stores";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			{/* redux */}
			<Provider store={store}>
				{/* React Query */}
				<QueryClientProvider client={queryClient} >
					<App />
					{/* <ReactQueryDevtools initialIsOpen /> */}
				</QueryClientProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>
);