import {
	alpha,
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from "@mui/material";
import {
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	BubbleChart as StepsIcon,
} from "@mui/icons-material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import GamesService from "services/gameService";
import { gameSlice } from "stores/slices/game";
import CustomizedDialog from "components/Dialog";
import { Card, CardBody, CardFooter, CardHeader } from "./Card";
import { CustomizedHeader } from "./Tools";
import { toast } from "react-toastify";
import { catalogSlice } from "stores/slices/catalog";

export default function GameSelector() {
	const theme = useTheme();
	const dispatch = useDispatch();	
	const gamesService = new GamesService();

	const { game: mainGame } = useSelector((state) => state.game);

	const [ selectedGame, setSelectedGame ] = useState(mainGame);
	const [ openDialog, setOpenDialog ] = useState(false);
	const [ selectionMode, setSelectionMode ] = useState("game");

	const isGameSelected = selectedGame && selectedGame._id ? true : false;

	const { data: games } = useQuery(
		["games"],
		async () => await gamesService.getAll(),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		}
	);

	const handleClose = () => {
		setOpenDialog(false);
		setSelectionMode("game");
	};

	const handleOpen = () => {
		setOpenDialog(true);
	};

	const handleSetMainGame = () => {
		dispatch(gameSlice.actions.setGame(selectedGame));
		dispatch(catalogSlice.actions.setCatalog({}));
		toast.success("The game has been selected.");
		if (selectedGame?.status === "maintenance") {
			toast.warning("This game is in maintenance.");
		}
		if (selectedGame?.status === "inactive") {
			toast.error("This game is inactive.");
		}
		handleClose();
	};

	const handleChangeGame = (event) => {
		const gameId = event.target.value;
		if (selectedGame?._id !== gameId) {
			setSelectedGame(games.find((g) => g._id === gameId));			
		}
	};

	useEffect(() => {				
		setSelectedGame(mainGame);		
	}, [mainGame]);

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				onClick={handleOpen}
				px={1}
				sx={{
					border: "1px solid",
					borderColor:
            mainGame?.status === "maintenance"
            	? theme.palette.warning.main
            	: mainGame?.status === "inactive"
            		? theme.palette.error.main
            		: theme.palette.divider,
					borderRadius: theme.shape.borderRadius,
					height: 70,
					width: 240,
					maxWidth: 240,
					cursor: "pointer",
					transition: "all 0.1s ease-in-out",
					"&:hover": {
						backgroundColor: alpha("#fff", 0.07),
					},
				}}
			>
				<Box>
					<Typography variant="h6">
						{mainGame?.displayName ?? "Select a Game"}
					</Typography>
					<Typography variant="subtitle2" color="text.secondary">
						{mainGame?._id ?? ""}
					</Typography>
				</Box>
				<Box p={0.5}>
					{openDialog ? (
						<KeyboardArrowUpIcon sx={{ color: theme.palette.primary.main }} />
					) : (
						<KeyboardArrowDownIcon sx={{ color: theme.palette.primary.main }} />
					)}
				</Box>
			</Box>
			<CustomizedDialog open={openDialog} onClose={handleClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader title={"Steps"} subtitle={"Select Game"} />
						<Box color={theme.palette.third.main}>
							<StepsIcon fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2}>
						<Grid container alignItems="center" justifyContent="center" mt={1}>
							<Grid item xs={8}>
								<FormControl fullWidth>
									<InputLabel id="game" color="third">
                    Game
									</InputLabel>
									<Select
										labelId="game"
										id="game"
										value={selectedGame?._id || ""}
										label="Game"
										color="third"
										onChange={handleChangeGame}
									>
										{games &&
                      games.map((g) => (
                      	<MenuItem key={g._id} value={g._id}>
                      		{g.displayName}
                      	</MenuItem>
                      ))}
									</Select>
									<FormHelperText>Select a Game</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</CardBody>
					<CardFooter justifyContent={"space-between"} p={1}>
						<Button
							onClick={handleClose}
							color="third"
							disabled={selectionMode === "game"}
						>
              CANCEL
						</Button>
						<Button
							onClick={handleSetMainGame}
							color="third"
							disabled={!isGameSelected ? true : false}
						>
              SET GAME AS ACTIVE
						</Button>
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
