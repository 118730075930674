import { createSlice } from "@reduxjs/toolkit";

const initialStateSidebar = () => {
	try {
		return localStorage.getItem("theme") || "dark";
	} catch (e) {
		return "default";
	}
};

export const themeSlice = createSlice({
	name: "theme",
	initialState: {
		theme: initialStateSidebar(),
	},
	reducers: {
		setTheme: (state, action) => {
			state.theme = action.payload;
			localStorage.setItem("theme", action.payload);
		},
	},
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;