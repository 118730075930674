import { createSlice } from "@reduxjs/toolkit";

const initialStateCatalog = () => {
	try {
		return JSON.parse(sessionStorage.getItem("catalog")) || {};
	} catch (e) {
		return {};
	}
};

export const catalogSlice = createSlice({
	name: "catalog",
	initialState: {
		catalog: initialStateCatalog(),
	},
	reducers: {
		setCatalog: (state, action) => {
			if (action.payload) {
				state.catalog = action.payload;
				sessionStorage.setItem("catalog", JSON.stringify(action.payload));
			} else {
				state.catalog = {};
				sessionStorage.removeItem("catalog");
			}
		},        
	},
});

export const { setCatalog } = catalogSlice.actions;
export default catalogSlice.reducer;