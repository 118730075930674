import { Email } from "@mui/icons-material";
import InvitationService from "services/invitationService";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box, Skeleton } from "@mui/material";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedAddButton, CustomizedBoxMessage, CustomizedDeleteButton, CustomizedHeader } from "components/Tools";
import CustomizedTable from "components/Table";
import FormDialogInvitations from "./FormDialogInvitations";
import ConfirmationDialog from "components/ConfirmationDialog";

const columnsInvitationsTable = [
	{
		id: "avatar",
		label: "Avatar",
		// icon: <FormatListBulleted />,
		type: "avatar",
		align: "center",
		borderRadious: "25%",
		default: <Email />,
		width: 10,
		disabledPadding: true,
	},
	{
		id: "email",
		label: "Email",
		type: "text",
		align: "left",
		sortable: true,
		// width: 200,
	},
	{
		id: "status",
		label: "Status",
		type: "text",
		align: "left",
		sortable: true,
		// width: 200,
	},
	{
		id: "role",
		label: "Role",
		type: "text",
		align: "left",
		sortable: true,
		// width: 200,
	},
	{
		id: "createdAt",
		label: "Created At",
		type: "date",
		align: "left",
		sortable: true,
		// width: 200,
	},
];

export default function Invitations() {	 		
	const queryClient = useQueryClient();
  
	const invitationService = new InvitationService();  

	// STATES
	const [ openConfirmationDelete, setOpenConfirmationDelete ] = useState(false);
	const [ openFormDialog, setOpenFormDialog ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formMode, setFormMode ] = useState("");
	const [ selectedItems, setSelectedItems ] = useState([]);	

	const keys = ["invitations"];

	const { data, isLoading } = useQuery(
		keys,
		async () => await invitationService.getAll(),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		}
	);

	// MUTATIONS

	const createMutation = useMutation(
		async (data) => await invitationService.create(data),
		{
			onSuccess: (newItem) => {
				if (!newItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return [ ...oldItems, newItem ];
				});
			},
		}
	);

	const updateMutation = useMutation(
		async (data) => await invitationService.update(data),
		{
			onSuccess: (updatedItem) => {
				if (!updatedItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					const index = oldItems.findIndex(
						(item) => item._id === updatedItem._id
					);
					oldItems[index] = updatedItem;
					return [...oldItems];
				});
			},
		}
	);

	const deleteMutation = useMutation(
		async (id) => await invitationService.delete(id),
		{
			onSuccess: (deletedItem) => {
				if (!deletedItem) {
					return;
				}
				queryClient.setQueryData(keys, (oldItems) => {
					return oldItems.filter((item) => item._id !== deletedItem._id);
				});
			},
		}
	);

	// HANLDES
	const handleAdd = () => {   
		setFormMode("create");
		setFormData({ role: "guest" });
		setOpenFormDialog(true);
	};

	// const handleSend = async (id) => {
	// 	await invitationService.sendEmail({ id });
	// };
    

	const handleEdit = (id) => {
		setFormMode("update");
		const item = data?.find((item) => item._id === id);
		setFormData(item);
		setOpenFormDialog(true);
	};

	const handleDelete = (id) => {
		setFormMode("delete");
		const item = data?.find((item) => item._id === id);
		setFormData(item);  
		setOpenConfirmationDelete(true);
	};

	const hanldeDeleteSelected = () => {
		setFormMode("deleteSelected");
		setOpenConfirmationDelete(true);
	};

	const onSubmit = async (data) => {
		if (formMode === "create") {
			// setFormData(data); // No es necesario      
			await createMutation.mutateAsync({ data });
		} 
		if (formMode === "update") {      
			// setFormData(data);  // No es necesario
			await updateMutation.mutateAsync({ id: data._id, data });
		}
		if (formMode === "delete") {				
			await deleteMutation.mutateAsync({ id: formData._id });
		}
		if(formMode === "deleteSelected"){
			selectedItems.forEach(async (id) => {
				await deleteMutation.mutateAsync({ id });
			});
			setSelectedItems([]);
		}
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);
	};  

	const onClose = () => {
		setOpenFormDialog(false);
		setOpenConfirmationDelete(false);
	};

	if (isLoading) {
		return <Skeleton variant="rounded" height={250} />;
	}	

	return (
		<>
			{/* TABLE OF Invitations */}
			<Card>
				<CardHeader p={2} py={1} justifyContent={"space-between"}>
					<CustomizedHeader
						title={"List of Invitations"}
						subtitle={"Manage your invitations"}
					/>
					<Box display="flex">
						<Box mr={0.75}>
							{selectedItems.length > 0 && (
								<CustomizedDeleteButton onClick={hanldeDeleteSelected} />
							)}
						</Box>
						<CustomizedAddButton onClick={handleAdd} title="Invitate" />
					</Box>
				</CardHeader>
				<CardBody maxHeight="24.5rem">
					{data && data.length > 0 && (
						<CustomizedTable
							itemKey="_id"
							selectabledRows
							selected={selectedItems}
							setSelected={setSelectedItems}
							columns={columnsInvitationsTable}
							rows={data}
							// onSend={handleSend}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					)}
					{data && data.length === 0 && (
						<CustomizedBoxMessage message={"Invitations not found"} />
					)}
				</CardBody>
				{data && data.length > 0 && (
					<CardFooter px={2} py={1} borderColor="#525252">
						{selectedItems.length > 0 &&
              `Selected ${selectedItems.length} of ${data.length}`}
						{selectedItems.length === 0 && `Total ${data.length}`}
					</CardFooter>
				)}
			</Card>
			{/* fORM OF Invitations */}
			<FormDialogInvitations
				isOpen={openFormDialog}
				onClose={onClose}
				onSubmit={onSubmit}
				data={formData}
				setData={setFormData}
				isEdit={formMode === "update"}
			/>
			{/* CONFIRM DELETE MESSAGE */}
			<ConfirmationDialog
				title={
					formMode === "delete"
						? `You want to delete ${formData.displayName} invitation?`
						: `You want to delete these ${selectedItems.length} invitations?`
				}
				subtitle={
					formMode === "delete"
						? "Are you sure you want to delete this invitation?"
						: `Are you sure you want to delete these ${selectedItems.length} invitations?`
				}
				message={
					formMode === "delete"
						? "This action can not be undone. If you delete this invitation, all data associated with it will be lost."
						: `This action can not be undone. If you delete these ${selectedItems.length} invitations, all data associated with it will be lost.`
				}
				open={openConfirmationDelete}
				onClose={onClose}
				onConfirm={onSubmit}
			/>
		</>
	);
}
