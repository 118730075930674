import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import axios from "utils/Axios";

const firebaseConfig = {
	apiKey: "AIzaSyC2XxS6ugd0ZpiXO6zFgZ87S6o0gzN5Knk",
	authDomain: "dev-admin-api-8a5f6.firebaseapp.com",
	projectId: "dev-admin-api-8a5f6",
	storageBucket: "dev-admin-api-8a5f6.appspot.com",
	messagingSenderId: "1071541140366",
	appId: "1:1071541140366:web:80ddc8c1ad8940cc6742df"
};

class Authentication {
	#userChangeCallbacks = [];	
	isInitialized = false;
	user = null;
	firebaseUser = null;
	app = null;
	auth = null;


	constructor() {
		this.user = null;
		this.firebaseUser = null;
		this.app = initializeApp(firebaseConfig);		
		this.auth = getAuth(this.app);
		this.auth.onAuthStateChanged(async (firebaseUser) => {
			await this.#handleAuthStateChanged(firebaseUser);
			this.isInitialized = true;
		});

		axios.interceptors.request.use(async (config) => {
			config.headers["Authorization"] = `Bearer ${await this.getSessionToken()}`;
			return config;
		});
	}

	static instance = this.instance || new Authentication();

	#handleAuthStateChanged = async (firebaseUser) => {		
		this.firebaseUser = firebaseUser;

		if (firebaseUser) {				
			this.user = await this.fetchUserData();						 
		}else{
			this.user = null;
		}

		this.#userChangeCallbacks.forEach((callback) => callback(this.user));
	};

	getSessionToken = async () => {
		if (this.auth.currentUser == null) {
			return null;
		}
		return this.auth.currentUser.getIdToken();
	};

	fetchUserData = async () => {	
		try {
			if (this.user) {
				return this.user;
			}
			const response = await axios.post("/auth/signin");			    
			if (!response.data?.data) {
				return null;
			}
			return response.data?.data;
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	onUserChange(callback) {
		if (this.isInitialized) {callback(this.user);}
		this.#userChangeCallbacks.push(callback);
		return () => this.offUserChange(callback);
	}

	offUserChange(callback) {
		this.#userChangeCallbacks = this.#userChangeCallbacks.filter((cb) => cb !== callback);
	}

	signInWithGoogle = async () => {
		const provider = new GoogleAuthProvider();
		const res = await signInWithPopup(this.auth, provider);				
		this.#handleAuthStateChanged(res.user);
	};

	signOut = async () => {
		await this.auth.signOut();
		this.#handleAuthStateChanged(null);
	};
}

export default Authentication.instance;