import { Grid } from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import Users from "./Users";


export default function Index() {
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Breadcrumbs />
				</Grid>

				<Grid item xs={12}>
					<Users/>
				</Grid>
			</Grid>
		</>
	);
}
