import {
	Book,	
} from "@mui/icons-material";
import {
	Grid,
	Tab,
	Tabs,
} from "@mui/material";
import { Card, CardBody, CardHeader } from "components/Card";

import React, { Suspense, useState } from "react";

export default function Index() {	
	const [ activeTab, setActiveTab ] = useState("catalogs");

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const LazyCatalogs = React.lazy(() => import("./Catalogs"));

	return (
		<>
			<Grid container spacing={2}>
				{/* TABLE OF GAMES */}
				<Grid item xs={12}>
					<Card>
						<CardHeader pt={1} px={2}>
							<Tabs value={activeTab} onChange={handleTabChange}>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Book style={{ fontSize: 20, marginRight: 5 }} />
                      Catalogs
										</div>
									}
									value={"catalogs"}
								/>
							</Tabs>
						</CardHeader>
						<CardBody height="49.5rem" px={2} py={1}>
							<Suspense fallback={<div>Loading...</div>}>
								{activeTab === "catalogs" && <LazyCatalogs />}
							</Suspense>
						</CardBody>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
