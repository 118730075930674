import React from "react";
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedHeader } from "components/Tools";
import { useDispatch, useSelector } from "react-redux";
import { gameSlice } from "stores/slices/game";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Selector({ game }) {
	const dispatch = useDispatch();	
	const navigate = useNavigate();

	const { game: gameActive } = useSelector((state) => state.game);
	
	const activeStep = game? 1 : 0;	
	const isActive = gameActive && game && gameActive?._id === game?._id ? true : false;

	const steps = [
		{
			label: game ? `${game.displayName} is selected` : "Select a game",
			description:
        "Create or select a game to manage. Once a game is created, you must select one to be able to manage it.",
		},
	];

	const handleSetActive = () => {
		if (isActive) {
			toast.info("The game is already active.");
			return;
		}
		dispatch(gameSlice.actions.setGame(game));		
		toast.success("The game is now active.");
		if (game?.status === "maintenance") {
			toast.warning("This game is in maintenance.");
		}
		if (game?.status === "inactive") {
			toast.error("This game is inactive.");
		}
		navigate("/app/data/players");
	};

	return (
		<>
			{/* TABLE OF GAMES */}
			<Card>
				<CardHeader p={2} py={1} justifyContent={"space-between"}>
					<CustomizedHeader
						title={"Manage your Games"}
						subtitle={"Create or select a game to manage"}
					/>
				</CardHeader>
				<CardBody maxHeight="24.5rem" px={2}>
					<Typography variant="body2" color="text.secondary">
            Before you can manage all the data for a game, you must first create
            a game and select it. Once you have selected a game, you can manage
            all the data for that game.
					</Typography>
					<Box p={2}>
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((step) => (
								<Step key={step.label}>
									<StepLabel>{step.label}</StepLabel>
									<StepContent>
										<Typography>{step.description}</Typography>
									</StepContent>
								</Step>
							))}
						</Stepper>
					</Box>
				</CardBody>
				<CardFooter px={4} pb={2}>
					<Button
						onClick={handleSetActive}
						disabled={!game || isActive}
					>
						{isActive ? "Success" : "Set them as active"}
					</Button>
				</CardFooter>
			</Card>
		</>
	);
}
