import Authentication from "auth/Authentication";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SingOut() {  
	const navigate = useNavigate();
	const signOut = async () => {
		await Authentication.signOut();
		navigate("/auth/signin", { replace: true });
	};

	signOut();
	return <React.Fragment />;
}
