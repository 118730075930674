import { Check, Close, Person } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedHeader } from "components/Tools";
import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomizedDialog from "components/Dialog";

export default function FormDialogUsers({
	data = {},	
	onClose = () => {},
	onSubmit,
	isEdit = false,
	isOpen = false,
}) {
	const theme = useTheme();
	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formErrors, setFormErrors ] = useState({});

	const handleOnChange = (e) => {
		const { name, value } = e.target;	    	
		const newData = { ...formData, [name]: value };
		setFormData(newData);
		validateForm(newData);
	};

	const handleOnChangeRole = (e) => {
		const { value } = e.target;
		const newData = { ...formData, role: value };
		setFormData(newData);
		validateForm(newData);
	};

	const handleOnChangeStatus = (e) => {
		const { value } = e.target;
		const newData = { ...formData, status: value };
		setFormData(newData);
		validateForm(newData);
	};

	const validateForm = (newData) => {
		const { uid, email, displayName, role, status } = newData;    
		const newErrors = formErrors;
		let isValid = true;
		if(!uid) {
			newErrors.uid = "User ID is required";
			isValid = false;
		}else {
			newErrors.uid = "";
		}
		if (!email) {
			newErrors.email = "Email is required";
			isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = "Email address is invalid";
			isValid = false;
		} else {
			newErrors.email = "";
		}
		if(!displayName || displayName.length < 3 || displayName.length > 30) {			
			newErrors.displayName = "Display name must be between 3 and 30 characters";
			isValid = false;
		}else {
			newErrors.displayName = "";
		}
		if(!role) {
			newErrors.role = "Role is required";
			isValid = false;
		}else {
			newErrors.role = "";			
		}
		if(!status) {
			newErrors.status = "Status is required";			
			isValid = false;
		}else {
			newErrors.status = "";			
		}		
		setFormErrors(newErrors);
		setIsFormValid(isValid);
	};
    


	const handleSubmit = (e) => {
		e.preventDefault();
		if(!isFormValid) {
			return;
		}
		onSubmit(formData);
	};

	useEffect(() => {
		if(isOpen) {
			setFormData(data);
			setFormErrors({});
			setIsFormValid(false);
			setTimeout(() => {
				document.getElementById("userUID").focus();
			}, 100);
		}
	}, [ data, isOpen ]);

	return (
		<>
			<CustomizedDialog open={isOpen} onClose={onClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader
							title={isEdit ? "Edit User" : "Add User"}
							subtitle={isEdit ? "Edit user details" : "Add a new user"}
						/>
						<Box color={theme.palette.third.main}>
							<Person fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2} py={1}>
						<Box component={"form"} onSubmit={handleSubmit}>
							<Grid container rowSpacing={1.5} columnSpacing={1.5}>
								<Grid item xs={12} pb={1.5}>
									<FormLabel component="legend">PROFILE</FormLabel>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="userUID"
										name="uid"
										label="UID"
										helperText={
											formErrors["uid"] || "Enter the user uid"
										}
										fullWidth
										required
										autoFocus
										color={"third"}
										autoComplete="off"
										inputProps={{
											minLength: 3,
											maxLength: 30,
										}}
										onChange={handleOnChange}
										value={formData["uid"] || ""}
										error={!!formErrors["uid"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="userEmail"
										name="email"
										label="Email"
										helperText={formErrors["email"] || "Enter the user email"}
										fullWidth
										required
										autoFocus
										color={"third"}
										autoComplete="off"
										inputProps={{
											minLength: 3,
											maxLength: 30,
										}}
										onChange={handleOnChange}
										value={formData["email"] || ""}
										error={!!formErrors["email"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="userDisplayName"
										name="displayName"
										label="Display Name"
										helperText={
											formErrors["displayName"] || "Enter the user dispaly name"
										}
										fullWidth
										required
										autoFocus
										color={"third"}
										autoComplete="off"
										inputProps={{
											minLength: 3,
											maxLength: 30,
										}}
										onChange={handleOnChange}
										value={formData["displayName"] || ""}
										error={!!formErrors["displayName"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl required fullWidth>
										<InputLabel id="userRoleLabel" color={"third"}>
                      Role
										</InputLabel>
										<Select
											labelId="userRoleLabel"
											id="userRole"
											value={formData.role || ""}
											label="Role *"
											onChange={handleOnChangeRole}
											color={"third"}
										>
											<MenuItem value={"admin"}>Admin</MenuItem>
											<MenuItem value={"user"}>User</MenuItem>
											<MenuItem value={"guest"}>Guest</MenuItem>
										</Select>
										<FormHelperText>
											{formErrors["role"] || "Enter the user role"}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl required fullWidth>
										<InputLabel id="userStatusLabel" color={"third"}>
                      Status
										</InputLabel>
										<Select
											labelId="userStatusLabel"
											id="userStatus"
											value={formData.status || ""}
											label="Status *"
											onChange={handleOnChangeStatus}
											color={"third"}
										>
											<MenuItem value={"active"}>active</MenuItem>
											<MenuItem value={"inactive"}>inactive</MenuItem>
										</Select>
										<FormHelperText>
											{formErrors["status"] || "Enter the user status"}
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
					</CardBody>
					<CardFooter justifyContent={"space-between"} p={1} px={2}>
						{/* Cancel Button */}
						<Button
							onClick={onClose}
							variant="text"
							color="third"
							startIcon={<Close />}
						>
              CANCEL
						</Button>
						{/* Save Button */}
						<Button
							onClick={handleSubmit}
							disabled={!isFormValid}
							color="third"
							startIcon={<Check />}
						>
              SAVE
						</Button>
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
