import { configureStore } from "@reduxjs/toolkit";
import appbarReducer from "./slices/appbar";
import catalogReducer from "./slices/catalog";
import gameReducer from "./slices/game";
import themeReducer from "./slices/theme";

export const store = configureStore({
	reducer: {
		appbar: appbarReducer,
		theme: themeReducer,
		game: gameReducer,
		catalog: catalogReducer,
	},
});