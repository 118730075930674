import { Check, Close, SportsEsports } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedHeader } from "components/Tools";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomizedDialog from "components/Dialog";

export default function FormDialogPlayers({
	data = {},	
	onClose = () => {},
	onSubmit,
	isEdit = false,
	isOpen = false,
}) {
	const theme = useTheme();
	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formErrors, setFormErrors ] = useState({});

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		const newData = { ...formData, [name]: value };
		setFormData(newData);
		validateForm(newData);
	};

	const handleOnChangeStatus = (e) => {
		const { value } = e.target;
		const newData = { ...formData, status: value };
		setFormData(newData);
		validateForm(newData);
	}; 
  
	const handleIsVerified = (e) => {
		const { checked } = e.target;
		const newData = { ...formData, isVerified: checked };
		setFormData(newData);
		validateForm(newData);
	};

	const validateForm = (newData) => {
		const { displayName, status } = newData;
		const newErrors = formErrors;
		let isValid = true;
		if (!displayName || displayName.length < 3 || displayName.length > 30) {
			newErrors.displayName =
        "Display name must be between 3 and 30 characters";
			isValid = false;
		} else {
			newErrors.displayName = "";
		}
		if (!status) {
			newErrors.status = "Status is required";
			isValid = false;
		} else {
			newErrors.status = "";
		}
		setFormErrors(newErrors);
		setIsFormValid(isValid);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if(!isFormValid) {
			return;
		}
		onSubmit(formData);
	};

	useEffect(() => {
		if(isOpen) {
			setFormData(data);
			setFormErrors({});
			setIsFormValid(false);
			setTimeout(() => {
				document.getElementById("playerDisplayName").focus();
			}, 100);
		}
	}, [ data, isOpen ]);

	return (
		<>
			<CustomizedDialog open={isOpen} onClose={onClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader
							title={isEdit ? "Edit Player" : "Add Player"}
							subtitle={isEdit ? "Edit player details" : "Add a new player"}
						/>
						<Box color={theme.palette.third.main}>
							<SportsEsports fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2} py={1}>
						<Box component={"form"} onSubmit={handleSubmit}>
							<Grid container rowSpacing={1.5} columnSpacing={1.5}>
								<Grid item xs={12} pb={1.5}>
									<FormLabel component="legend">PLAYER</FormLabel>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="playerDisplayName"
										name="displayName"
										label="Display Name"
										helperText={
											formErrors["displayName"] || "Enter the display name"
										}
										fullWidth
										required
										color={"third"}
										autoComplete="off"
										inputProps={{
											minLength: 2,
											maxLength: 30,
										}}
										onChange={handleOnChange}
										value={formData["displayName"] || ""}
										error={!!formErrors["displayName"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="playerImageUrl"
										name="imageUrl"
										label="Image URL"
										helperText={formErrors["imageUrl"] || "Enter the image url"}
										fullWidth
										required
										color={"third"}
										autoComplete="off"
										inputProps={{
											minLength: 2,
										}}
										onChange={handleOnChange}
										value={formData["imageUrl"] || ""}
										error={!!formErrors["imageUrl"]}
									/>
								</Grid>
								{/* isAnonaymous */}
								<Grid item xs={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={formData.isVerified || false}
												onChange={handleIsVerified}
												name="isVerified"
												color="primary"
											/>
										}
										label="isVerified"
									/>
								</Grid>

								<Grid item xs={12}>
									<FormControl required fullWidth>
										<InputLabel id="playerStatusLabel" color={"third"}>
                      Status
										</InputLabel>
										<Select
											labelId="playerStatusLabel"
											id="playerStatus"
											value={formData.status || ""}
											label="Status *"
											onChange={handleOnChangeStatus}
											color={"third"}
										>
											<MenuItem value={"active"}>active</MenuItem>
											<MenuItem value={"banned"}>banned</MenuItem>
											<MenuItem value={"inactive"}>inactive</MenuItem>
										</Select>
										<FormHelperText>
											{formErrors["status"] || "Enter the user status"}
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
					</CardBody>
					<CardFooter justifyContent={"space-between"} p={1} px={2}>
						{/* Cancel Button */}
						<Button
							onClick={onClose}
							variant="text"
							color="third"
							startIcon={<Close />}
						>
              CANCEL
						</Button>
						{/* Save Button */}
						<Button
							onClick={handleSubmit}
							disabled={!isFormValid}
							color="third"
							startIcon={<Check />}
						>
              SAVE
						</Button>
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
