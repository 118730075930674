import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Authentication from "auth/Authentication";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppBar from "components/AppBar";
import Sidebar from "components/Sidebar";
import DataSettings from "pages/data/settings/index";
import DataGames from "pages/data/games/index";
import DataPlayers from "pages/data/players/index";
import DataEconomy from "pages/data/economy/index";
import AdminUsers from "pages/admin/users";
import AdminInvitations from "pages/admin/invitations";
import DataEconomyCatalogs from "pages/data/economy/catalogs/index";

const AppContent = ({ children }) => {
	const theme = useTheme();

	return (
		<>
			<AppBar />
			<Box display="flex" minHeight="calc(100vh - 80px)">
				<Sidebar />
				<Box component="main" flex={12} pl={1} pr={2} pt={0.25} pb={1}>
					<Box
						height="100%"
						width="100%"
						bgcolor={theme.palette.background.paperLight}
						borderRadius={theme.shape.borderRadius}
						p={2}
					>
						{children}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default function AppIndex() {
	const isAdmin = Authentication.user?.role === "admin";
	return (
		<AppContent>
			<Routes>
				{/* Routes */}
				<Route index element={<Navigate to={"data"} />} />
				<Route path="data">
					<Route index element={<Navigate to="games" />} />
					<Route path="games" element={<DataGames />} />
					<Route path="players" element={<DataPlayers />} />
					<Route path="economy">
						<Route index element={<DataEconomy />} />
						<Route path="catalogs" element={<DataEconomyCatalogs />} />
					</Route>
					{isAdmin && (
						<>
							<Route path="setting" element={<DataSettings />} />
						</>
					)}
				</Route>
				{isAdmin && (
					<Route path="admin">
						<Route index element={<Navigate to="users" />} />
						<Route path="users" element={<AdminUsers />} />
						<Route path="invitations" element={<AdminInvitations />} />
					</Route>
				)}
				<Route path="*" element={<h1>404</h1>} />
			</Routes>
		</AppContent>
	);
}
