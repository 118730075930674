import { toast } from "react-toastify";
import ServiceBase from "./ServiceBase";

class InvitationService extends ServiceBase {
	async getAll({ params } = {}) {
		const url = "/admin/invitations";
		return await super.get(url, params);
	}

	async get({ id }) {
		const url = `/admin/invitations/${id}`;
		return await super.get(url, {});
	}

	async create({ data }) {
		const url = "/admin/invitations";
		const newItem = await super.post(url, data);
		if (newItem) {
			toast.success(`Invitation ${newItem.displayName} added successfully`);
		}
		return newItem;
	}

	async update({ id, data }) {
		const url = `/admin/invitations/${id}`;
		const udpatedItem = await super.put(url, data);
		if (udpatedItem) {
			toast.success(`Invitation ${udpatedItem.displayName} updated successfully`);
		}
		return udpatedItem;
	}

	async delete({ id }) {  
		const url = `/admin/invitations/${id}`;
		const deletedItem = await super.delete(url);
		if (deletedItem) {
			toast.success(`Invitation ${deletedItem.displayName} deleted successfully`);
		}
		return deletedItem;
	}
}

export default InvitationService;
