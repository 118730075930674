import ServiceBase from "./ServiceBase";
import { toast } from "react-toastify";

class CatalogService extends ServiceBase {
    
	async getAll({ gameId, params= null }= {}) {		
		if(!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs`;
		return await super.get(url, params);
	}

	async get({ gameId, id }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs/${id}`;
		return await super.get(url, {});
	}

	async create({ gameId, data }) {		
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs`;
		const newItem = await super.post(url, data);
		if (newItem) {
			toast.success(`Catalog ${newItem.displayName} added successfully`);
		}
		return newItem;
	}

	async duplicate({ gameId, id, data }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs/${id}/duplicate`;
		const udpatedItem = await super.post(url, data);
		if (udpatedItem) {
			toast.success(`Catalog ${udpatedItem.displayName} duplicated successfully`);
		}
		return udpatedItem;
	}

	async update({ gameId, id, data }) {		
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs/${id}`;
		const udpatedItem = await super.put(url, data);
		if (udpatedItem) {
			toast.success(`Catalog ${udpatedItem.displayName} updated successfully`);
		}
		return udpatedItem;
	}

	async delete({ gameId, id }) {
		if (!gameId) {
			toast.error("Game id is required");
			return false;
		}
		const url = `/data/games/${gameId}/catalogs/${id}`;
		const deletedItem = await super.delete(url);
		if (deletedItem) {
			toast.success(`Catalog ${deletedItem.displayName} deleted successfully`);
		}
		return deletedItem;
	}
}

export default CatalogService;
