export default function formatDate(dateString) {
	const dateObj = new Date(dateString);

	if (isNaN(dateObj.getTime())) {
		// Si el objeto Date es inválido, devolvemos el texto original
		return dateString;
	}

	const options = {
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: true, // Habilita el formato de 12 horas (AM/PM)
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		timeZone: "UTC", // Establece la zona horaria a UTC
	};

	const formattedDateTime = dateObj.toLocaleString("es-US", options);

	return formattedDateTime;
}
