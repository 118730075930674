import {
	Https,
	Settings,
	SportsEsports,
	TextFields,
} from "@mui/icons-material";
import { Grid, Tab, Tabs } from "@mui/material";
// import Breadcrumbs from "components/Breadcrumbs";
import { Card, CardBody, CardHeader } from "components/Card";

import React, { Suspense, useState } from "react";

export default function Index() {
	
	const [ activeTab, setActiveTab ] = useState(0);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const TabContent1 = React.lazy(() => import("./TitleData"));
	const TabContent2 = React.lazy(() => import("./ApiKeys"));
	const TabContent3 = React.lazy(() => import("./FormPlayGamesCredentials"));
	const TabContent4 = React.lazy(() => import("./Settings"));
	
	return (
		<>
			<Grid container spacing={2}>
				{/* TABLE OF GAMES */}
				<Grid item xs={12}>
					<Card>
						<CardHeader pt={1} px={2}>
							<Tabs value={activeTab} onChange={handleTabChange} >
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<TextFields style={{ fontSize: 20, marginRight: 5 }} />
                      Title Data
										</div>
									}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Https style={{ fontSize: 20, marginRight: 5 }} />
                      Api Keys
										</div>
									}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<SportsEsports style={{ fontSize: 20, marginRight: 5 }} />
                      PlayGames Credentials
										</div>
									}
								/>
								<Tab
									label={
										<div style={{ display: "flex", alignItems: "center" }}>
											<Settings style={{ fontSize: 20, marginRight: 5 }} />
                      Settings
										</div>
									}
								/>
							</Tabs>
						</CardHeader>
						<CardBody height="49.5rem" px={2} py={1}>
							<Suspense fallback={<div>Cargando...</div>}>
								{activeTab === 0 && <TabContent1 />}
								{activeTab === 1 && <TabContent2 />}
								{activeTab === 2 && <TabContent3 />}
								{activeTab === 3 && <TabContent4 />}
							</Suspense>
						</CardBody>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
