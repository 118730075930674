import { useSelector } from "react-redux";
import darkTheme from "themes/dark";
import lightTheme from "themes/llight";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Loading from "components/Loading";
import CustomizedToastContainer from "components/ToastContainer";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Authentication from "auth/Authentication";
import SignIn from "pages/SignIn";
import SingOut from "pages/SignOut";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import "react-toastify/dist/ReactToastify.css";
import AppIndex from "pages/App";

const themes = {
	light: lightTheme,
	dark: darkTheme,
};


function App() {
	const navigate = useNavigate();	

	const { theme } = useSelector((state) => state.theme);

	const [ isLoadingUser, setIsLoadingUser ] = useState(true);
	const [ isAuthenticated, setIsAuthenticated ] = useState(false); // eslint-disable-line no-unused-vars
	
	useEffect(() => {
		const handleUserChange = (user) => {					
			if (!user) {
				navigate("/auth/signin", { replace: true });
			}
			setIsAuthenticated(user !== null);
			setIsLoadingUser(false);
		};

		Authentication.onUserChange(handleUserChange);
		return () => {
			Authentication.offUserChange(handleUserChange);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ThemeProvider theme={themes[theme]}>
			<CssBaseline />
			<CustomizedToastContainer/>      
			{isLoadingUser && <Loading/>}
			{!isLoadingUser &&
        <Routes>
        	<Route index element={<Navigate to={"app"} />} />
        	<Route path="auth" >
        		<Route path="signin" element={<SignIn />} />        		
        		<Route path="signout" element={<SingOut />} />            
        	</Route>        	
        	{isAuthenticated && <Route path="app/*" element={<AppIndex />} />}
        	<Route path="*" element={<h1>404</h1>} />
        </Routes> 
			} 
		</ThemeProvider>
	);
}

export default App;
