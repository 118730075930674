import { Check, Close, SportsEsports } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "components/Card";
import { CustomizedHeader } from "components/Tools";
import { Box, Button, FormLabel, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomizedDialog from "components/Dialog";

export default function FormDialogGames({
	data = {},	
	onClose = () => {},
	onSubmit,
	isEdit = false,
	isOpen = false,
}) {
	const theme = useTheme();
	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ formErrors, setFormErrors ] = useState({});

	const handleOnChange = (e) => {
		const { name, value, validationMessage } = e.target;		
		setFormData((prev) => ({ ...prev, [name]: value }));
		setFormErrors((prev) => ({ ...prev, [name]: validationMessage }));
		setIsFormValid(e.target.form.checkValidity());
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if(!isFormValid) {
			return;
		}
		onSubmit(formData);
	};

	useEffect(() => {
		if(isOpen) {
			setFormData(data);
			setFormErrors({});
			setIsFormValid(false);
			setTimeout(() => {
				document.getElementById("gameDisplayName").focus();
			}, 100);
		}
	}, [ data, isOpen ]);

	return (
		<>
			<CustomizedDialog open={isOpen} onClose={onClose}>
				<Card>
					<CardHeader p={2} py={1} justifyContent={"space-between"}>
						<CustomizedHeader
							title={isEdit ? "Edit Game" : "Add Game"}
							subtitle={isEdit ? "Edit game details" : "Add a new game"}
						/>
						<Box color={theme.palette.third.main}>
							<SportsEsports fontSize={"large"} />
						</Box>
					</CardHeader>
					<CardBody px={2} py={1}>
						<Box component={"form"} onSubmit={handleSubmit}>
							<FormLabel component="legend" sx={{ marginBottom: 1.5 }}>
                DISPLAYED
							</FormLabel>
							<TextField
								id="gameDisplayName"
								name="displayName"
								label="Display Name"
								helperText={
									formErrors["displayName"] || "Enter the game dispaly name"
								}
								fullWidth
								required
								autoFocus
								color={"third"}
								autoComplete="off"
								inputProps={{
									minLength: 3,
									maxLength: 30,
								}}
								onChange={handleOnChange}
								value={formData["displayName"] || ""}
								error={!!formErrors["displayName"]}
							/>
						</Box>
					</CardBody>
					<CardFooter justifyContent={"space-between"} p={1} px={2}>
						{/* Cancel Button */}
						<Button
							onClick={onClose}
							variant="text"
							color="third"
							startIcon={<Close />}
						>
              CANCEL
						</Button>
						{/* Save Button */}
						<Button
							onClick={handleSubmit}
							disabled={!isFormValid}
							color="third"
							startIcon={<Check />}
						>
              SAVE
						</Button>
					</CardFooter>
				</Card>
			</CustomizedDialog>
		</>
	);
}
