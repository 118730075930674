import { Grid } from "@mui/material";
import Breadcrumbs from "components/Breadcrumbs";

import React, { useState } from "react";
import Games from "./Games";
import Selector from "./Selector";



export default function Index() {
	const [ game, setGame ] = useState(null);

	const hableChangeGame = (game) => {
		setGame(game);
	};

	return (
		<>
			<Grid container spacing={2}>
				{/* BREADCRUMBS */}
				<Grid item xs={12}>
					<Breadcrumbs />
				</Grid>
				{/* TABLE OF GAMES */}
				<Grid item xs={8}>
					<Games setGame={hableChangeGame} />
				</Grid>
				{/* SELECTOR */}
				<Grid item xs={4}>
					<Selector game={game} />
				</Grid>
			</Grid>
		</>
	);
}
